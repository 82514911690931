import { Button, Flex, Textarea } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { log } from '@cccom/shared/logging';
import { zCCString, zCCStringCharLimit } from '@cccom/shared/utils/common';

import { DebouncedButton } from '../debounced-button/DebouncedButton';
import { CCFormControl } from '../form-control/CCFormControl';

type AddCommentProps = {
  onSubmit: (data: CommentData) => Promise<void>;
  maxLength?: number;
};

const COMMENT_CHAR_LIMIT = 500;

const commentSchema = z.object({
  message: zCCString({ field: 'Comment' }).and(
    zCCStringCharLimit({ char_limit: COMMENT_CHAR_LIMIT })
  ),
});

type CommentData = z.infer<typeof commentSchema>;

export function AddComment({
  onSubmit,
  maxLength = COMMENT_CHAR_LIMIT,
}: AddCommentProps) {
  const [t] = useTranslation();
  const [showActionButtons, setShowActionButtons] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<CommentData>({
    resolver: zodResolver(commentSchema),
    defaultValues: { message: '' },
    mode: 'onChange',
  });

  const handleSubmitComment = async (data: CommentData) => {
    try {
      await onSubmit(data);
      reset();
      setShowActionButtons(false);
    } catch (error) {
      log.error('Failed to submit comment:', error);
    }
  };

  const cancelHandler = () => {
    reset();
    setShowActionButtons(false);
  };

  return (
    <Flex direction="column" gap="4">
      <CCFormControl error={errors.message}>
        <Textarea
          onFocus={() => setShowActionButtons(true)}
          id="comment"
          rows={6}
          maxLength={maxLength + 1}
          resize="none"
          placeholder={t('common.actions.add_a_comment')}
          {...register('message')}
          data-cy="comment"
          _placeholder={{ color: 'gray.400' }}
          isInvalid={!!errors?.message?.message}
          errorBorderColor="red.500"
        />
      </CCFormControl>

      <Flex
        visibility={showActionButtons ? 'visible' : 'hidden'}
        gap="2"
        alignSelf="start"
        mb="2"
      >
        <DebouncedButton
          size="sm"
          colorScheme="brand"
          variant="primary"
          onClick={handleSubmit(handleSubmitComment)}
          isDisabled={!isDirty || !isValid}
        >
          {t('common.actions.save_comment')}
        </DebouncedButton>
        <Button
          size="sm"
          colorScheme="brand"
          variant="outline"
          onClick={cancelHandler}
        >
          {t('common.actions.cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}
