import { z } from 'zod';

import { i18n } from '@cccom/shared/i18n';

const notOnlySpecialChars = /(?:[A-Za-z\d])|^(?![\s\S])/;

type FieldMessage = {
  field: string;
  message?: string;
};

export function zCCRequired({
  field,
  message = i18n.t('forms.required', { 0: field }),
}: FieldMessage) {
  return z
    .string({
      invalid_type_error: message,
    })
    .refine((val) => val.length > 0, { message });
}

export function zCCNoSpecialChars({
  field,
  message = i18n.t('forms.not_only_spec_chars', { 0: field }),
}: FieldMessage) {
  return z
    .string()
    .refine((val) => val.match(notOnlySpecialChars), { message });
}

/**
 * display error if
 * - string is empty
 * - string has leading or trailing spaces
 * - string contains only special chars (no letters or numbers)
 *
 * @param field - label of property
 * @param required_error - override required error
 *
 */
export function zCCString({
  field,
  required_error,
}: {
  field: string;
  required_error?: string;
}) {
  return z
    .string()
    .and(zCCRequired({ field, message: required_error }))
    .and(zCCNoSpecialChars({ field }));
}

export function zCCStringCharLimit({
  char_limit,
  message = i18n.t('forms.char_limit', { 0: char_limit }),
}: {
  char_limit: number;
  message?: string;
}) {
  return z.string().max(char_limit, {
    message,
  });
}

export function zCCStringOptional({ field }: { field: string }) {
  return z.string().and(zCCNoSpecialChars({ field })).nullish();
}
